import React from 'react';


function SmallLoader() {
    return (
        <div className="animated fadeIn pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
    );
}


export default SmallLoader;
