import React, { Component } from 'react';

export const Context = React.createContext();

export default class Provider extends Component {
    constructor() {
        super();
        this.state = {
            pin: localStorage.getItem('pin', ''),
            isAuth: localStorage.getItem('isAuth', false),
        }

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    login() {
        if (this.state.pin) {
            this.setState({ isAuth: true, });

            localStorage.setItem('pin', this.state.pin);
            localStorage.setItem('isAuth', true);
        }
    }

    logout() {
        localStorage.removeItem('pin');
        localStorage.removeItem('isAuth');
        this.setState({ pin: '', isAuth: false, data: {} });
    }

    render() {
        return (
            <Context.Provider
                value={{
                    isAuth: this.state.isAuth,
                    pin: this.state.pin,
                    login: this.login,
                    logout: this.logout,
                    handleChange: this.handleChange,
                }}
            >
                {this.props.children}
            </Context.Provider>
        )
    }
}

const Consumer = Context.Consumer

export { Provider, Consumer }