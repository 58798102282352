import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { Provider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import SmallLoader from './components/SmallLoader';
import { SENTRY_DSN } from './utils/constants';
import './App.scss';


if (SENTRY_DSN) {
    Sentry.init({dsn: "https://292a0c0b6e6a469da12954a854579cc1@o76793.ingest.sentry.io/5397507"});
}


// Pages
const Login = React.lazy(() => import('./views/Login'));
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));


function App() {
    return (
        <HashRouter>
            <Provider>
                <React.Suspense fallback={<SmallLoader />}>
                <Switch>
                    <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                    <ProtectedRoute name="QrReader" path="/" component={DefaultLayout} />
                </Switch>
                </React.Suspense>
            </Provider>
        </HashRouter>
    );
}

export default App;
